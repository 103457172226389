import { FeedbackDTO } from '../models/dtos/feedbackDto'
import { IKitService } from './interfaces/kit-service-interface'
import { IKitResource } from '../resources/interfaces/kit-resource-interface'

export default class KitService implements IKitService {
  constructor (
    private readonly resource: IKitResource,

  ) {

    this.resource = resource
  }


  

  public sendFeedback (user:FeedbackDTO): Promise<any> {

    return this.resource.sendFeedback(user)
    
  }



 
}
