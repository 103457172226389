import { useState } from "react"
import { IAuthenticatorService } from "../../../domain/services/interfaces/authenticator-service-interface"
import { IEmailService } from "../../../domain/services/interfaces/email-service-interface"

export type RegisterPageProps = {
  emailService: IEmailService
  authService: IAuthenticatorService


}

const RegisterPage: React.FC<RegisterPageProps> = (props: RegisterPageProps) => {
  
  const { authService} = props
  
  const [email, setEmail] = useState<string>()


  


  const requestAccess = () =>
    {
      console.log('atualizar',email)
      if(!email) return
      authService.recoverPassword(email).finally( () => console.log('ok'))
     
    }
  


  return (

    <div className="lt-xp-form-register">

    <div className="columns">
     
      <div className="column">
        <div className="field">
          <label className="label">E-mail de cadastro(o mesmo da assinatura)</label>
          <div className="control">
            <input  className="input" type="text" placeholder="assinante@gmail.com" value={email} onChange={(event: any)=> setEmail(event.target.value)}/>
          </div>
        </div>

      </div>
    </div>

  
    <div className="field is-grouped">
      <div className="control">
        <button onClick={requestAccess} className="button is-link">Solicitar Acesso</button>
      </div>
      <div className="control">
        <button className="button is-link is-light">Limpar</button>
      </div>
    </div>

  </div>

  )
}


export default RegisterPage
