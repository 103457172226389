import { Container } from '@mui/material';
import LtHeader from '../../components/header';
import AuthProvider from '../../../domain/contexts/auth-context';



function VeteransGroupRewardPage() {

  return (
    <>
      <LtHeader />
      <Container maxWidth="sm">
        <AuthProvider>

          <div className="card">
            <div className="card-content">
              <p className="title">Parabéns!</p>
              <div className='content'>
                <i>Você atingiu seis meses de casa e ganhou acesso ao 7CX - nosso grupo exclusivo para veteranos no WhatsApp </i> <br></br>
                <div>
                    <p>                
                        ✅ Acesso antecipado a novidades do clube
                    </p>
                    <p>
                        ✅Prioridade nas ações da comunidade 
                    </p> 
                    <p>
                        ✅ Ações exclusivas 
                    </p>  
                     <p>
                        ✅ Programa de indicações exclusivo
                    </p>
                 </div>
                    
              </div>
              <div className="buttons">
                <button className="button is-primary is-fullwidth " onClick={() => window.open('https://chat.whatsapp.com/KuQrwrHTWgH3DafBZr1A9q', '_blank')}>Resgatar acesso a 7CX</button>
              </div>
            </div>
            <footer className="card-footer">
            
            </footer>
          </div>         </AuthProvider>
      </Container>
    </>

  );
}

export default VeteransGroupRewardPage;
