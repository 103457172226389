import { CategoryEnum } from "./enums/categoryEnum";

export class Category {
  public id?: string | number;
  public label?: string 

  constructor(id: string | number, label?: string) {
    this.id = id;
    this.label = label;
  }

  //FIXME: Refatorar para usar array associatva no lugar de switchcase
  static getLabelFromValue(value: number) {
  
    if (value == null) return "Não Informada";

    switch (value) {
      case CategoryEnum.ROMANCE:
        return "Romance"
      case CategoryEnum.DARK:
        return "Dark"
      case CategoryEnum.LIGHT:
        return "Light"
      case CategoryEnum.PESSOAS:
        return "Pessoas"
      case CategoryEnum.CLASSICOS:
        return "Clássicos"
      case CategoryEnum.NERD:
        return "Nerd"
      case CategoryEnum.ROMANCE_18:
        return "Romance + 18"
      case CategoryEnum.SURPRESA:
        return "Supresa"
      default:
        return "Não encontrada";
    }
  }
}
