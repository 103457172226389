import { IUserService } from './interfaces/user-service-interface'
import { IUserResource } from '../resources/interfaces/user-resource-interface'
import { UserDTO } from '../models/dtos/userDto'

export default class UserService implements IUserService {
  constructor (
    private readonly resource: IUserResource,

  ) {

    this.resource = resource
  }

  public get (user: UserDTO): Promise<UserDTO> {
    if(!user.id) return Promise.resolve(new UserDTO())
    return this.resource.get(user.id)
    
  }

  
  public update (user: UserDTO): Promise<UserDTO> {
    if(!user.id) return Promise.resolve(new UserDTO())

    return this.resource.update(user.id,user)
    
  }

  public getSubscriptionDetails (user: UserDTO): Promise<any> {
  
    return this.resource.getSubscriptionDetails(user)
  
    }

    
       

 
}
