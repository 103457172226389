import { BaseApiResource } from "./base-api-resource";
import { FeedbackDTO } from "../models/dtos/feedbackDto";
import { IKitResource } from "./interfaces/kit-resource-interface";

export default class KitResource extends BaseApiResource implements IKitResource {

    public constructor() {
        super("kits/")
    }


    public sendFeedback(feedback: FeedbackDTO): Promise<any> {
            return this.post( feedback,"feedbacks")

    }

   
}