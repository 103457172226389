import { UserDTO } from "../models/dtos/userDto";
import { BaseApiResource } from "./base-api-resource";
import { IUserResource } from "./interfaces/user-resource-interface";


export default class UserResource extends BaseApiResource implements IUserResource {
    public constructor() {
        super("usuarios/")
           
    }

        public getWithSubscription(user: UserDTO): Promise<UserDTO> {
            if(!user.id) return Promise.resolve({email : ""})
            return this.get(user.id,'status-assinatura/')
        
        }
    

 

   
}