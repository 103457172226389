import { useEffect, useState } from "react"
import { IBookSuggestionService } from "../../../domain/services/interfaces/book-suggestion-service-interface"
import { CategoryEnum } from "../../../domain/models/enums/categoryEnum"
import { Category } from "../../../domain/models/category"
import { ICategoryService } from "../../../domain/services/interfaces/category-service-interface"
import LtHeader from "../../components/header"
import { SubscriptionEnum } from "../../../domain/models/enums/subscriptionEnum"
import { UserDTO } from "../../../domain/models/dtos/userDto"
import { UserWithSubscriptionDTO } from "../../../domain/models/dtos/userWithSubscriptionDTO"
import { LocalStorageKeys } from "../../../domain/resources/local-storage-keys"
import { Book } from "../../../domain/models/book"

export type BookSuggestionPageProps = {
  service: IBookSuggestionService
  categoryService: ICategoryService

}

const BookSuggestionPage: React.FC<BookSuggestionPageProps> = (props: BookSuggestionPageProps) => {
  const { service, categoryService } = props

  const userId = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY)


  const defaultUser: UserWithSubscriptionDTO = {
    id: userId as unknown as number,
    email: "",
    categoriaPrimaria: CategoryEnum.CLASSICOS,
    categoriaSecundaria: CategoryEnum.CLASSICOS,
    idSkoob: "",
    aceita18: 0,
    tipoAssinatura: SubscriptionEnum.DETACHED
  }


  const [requestCategory, setRequestCategory] = useState<CategoryEnum>(CategoryEnum.SURPRESA)
  const [suggestedBook, setSuggestedBook] = useState<Book>()
  const [bookCover, setBookCover] = useState<string>("https://upload.wikimedia.org/wikipedia/commons/b/b9/No_Cover.jpg")
  const categories = categoryService.findAll({ includeSurpresa: true });
  const [user, setUser] = useState<UserDTO>(defaultUser)




  useEffect(() => {
  }, [])

  const generateNewSuggestion = (): void => {
    console.log('VAI GERAR UMA NOVA SUGESTAO DE LEITURA...')
    service.getReadingSuggestion(requestCategory as number, {id: 77, userId : user.id?.toString(),email :  user.email})
    .then((suggestion) => setSuggestedBook(suggestion[0]))
    .catch(error => console.log('deu erro', error))

  }

  return (
    <>
      <LtHeader />
      <div className="lt-xp-form-book-suggestion box">
        <section className="lt-book-suggestions-page-title hero has-background-secondary">

          <h1 className="is-size-1 has-text-weight-bold">Sugestão de Leitura</h1>
        </section>
        <div className="lt-books-suggestions-book-page-label">
          <h2 className="is-size-2 "> LIVRO INDICADO PARA O SEU PERFIL É</h2>

        </div>

        <div className="lt-book-suggestions-book">

          <div className="lt-book-suggestion-cover">
            <img src={bookCover} alt="Capa da sua próxima leitura" id="capaLivro" />
          </div>

          <div className="lt-book-suggestions-description">
           
            {suggestedBook?.titulo ? <div>
              O livro <i>{suggestedBook?.titulo}</i> ,é o candidato ideal para sua próxima leitura, baseado em seu perfil,
              levando em conta suas estantes de leituras, feedbacks e livros recebidos em sua assinatura.
              </div> : <div>Selecione a categoria de sua preferência para conhecer sua sugestão de leitura</div>}

 
          </div>

         

        </div>

        <div className="lt-book-suggestions-container-footer">
          <div className="columns">
            <div className="column ">
              <div className="field">
                <label className="label">Categoria Primária</label>
                <div className="control  is-expanded">
                  <div className="select select is-fullwidth">
                    <select name="categoriaPrimaria" onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setRequestCategory(parseInt(event.target.value))} value={requestCategory}>
                      <option>Selecione</option>
                      {categories.map((category: Category, key: number) =>
                        <option key={key} value={category.id}>{category.label}</option>
                      )}

                    </select>
                  </div>
                </div>
              </div>

              <div className="field ">
                <div className="control is-expanded">
                  <button onClick={generateNewSuggestion} className="button is-link">Nova Sugestão</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>

  )
}

export default BookSuggestionPage
