const useLiteratourAPI = (): any => {
  //const BACKEND_API_URL: string = "http://localhost:8080/api-literatour/";
  const BACKEND_API_URL: string = "https://api-v2.literatour.com.br/";


  const requestHeaders =  {
      "Content-Type": "application/json",
      "Accept": "text/javascript",
     "Authorization": "Basic bGl0ZXJhdG91clVzZXI6bDJrY0FwcEBQYXNz",
    };

  return [BACKEND_API_URL, requestHeaders];
};

export default useLiteratourAPI;
