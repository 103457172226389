
export type BookshelfPageProps = {

}

const BookshelfPage: React.FC<BookshelfPageProps> = (props: BookshelfPageProps) => {
  type RouteProps = {
    codigoProcedimento: string
  }

  //const { possuiRoles } = useContext(UsuarioContext)
  //const routeParams = useParams<RouteProps>()
  //const broadcastChannel = new BroadcastChannel('policial_channel')
  //const { nomeUsuario, matricula } = makePortalIntegraAdapter()?.getUser()

  

  return (

<>
ESTANTE</>

  )
}

export default BookshelfPage
