
import React, { useEffect, useState } from 'react';

export type LtStarRatingProps = {
  value?: number
  onClick? : (value : number) => void 

}
const LtStarRating: React.FC<LtStarRatingProps> = (props: LtStarRatingProps) => {
  const [rating, setRating] = useState( props.value ?? 5);

  const handleStarClick = (star: number) => {
    setRating(star)
    if(props.onClick) props.onClick(star)
  }

  useEffect(() => {
    setRating(props.value as number)
  }, [props.value])


  return (
    <div>
      {[1,2 ,3,  4, 5].map((star) =>
      (
        <span
          key={"lt-star-component"+star}
          style={{ cursor: 'pointer' }}
          onClick={() => handleStarClick(star)}
        >
          {star <= rating ? '★' : '☆'}
        </span>
      ))}



    </div>
  )
}

export default LtStarRating;