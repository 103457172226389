import { AccessToken } from '../models/accessToken'
import { LoginVO } from '../models/dtos/loginVo'
import { User } from '../models/user'
import { IAuthenticatorResource } from '../resources/interfaces/authenticator-resource-interface'
import { IAuthenticatorService } from './interfaces/authenticator-service-interface'

export default class AuthenticatorService implements IAuthenticatorService {
  constructor (
    private readonly resource: IAuthenticatorResource,

  ) {

    this.resource = resource
  }


  public store (user: User): Promise<User> {

    return this.resource.store(user)
    
  }
  

  public login (user: LoginVO): Promise<AccessToken> {

    return this.resource.login(user)
    
  }

  public validateRecoveryCode (userMail : string, code : string): Promise<boolean> {

    return this.resource.validateRecoveryCode(userMail, code)
    
  }

  public recoverPassword (userMail: string): Promise<boolean> {

    return this.resource.recoverPassword(userMail)
    
  }

  public updatePasswordRecoveredAccount (userMail : string, code : string, newPassord: string): Promise<boolean> {

    return this.resource.updatePasswordRecoveredAccount(userMail, code, newPassord)
    
  }


 

 
}
