import { Container } from '@mui/material';
import AuthProvider from './domain/contexts/auth-context';

import { useNavigate } from 'react-router-dom';
import './App.css';
import LtHeader from './view/components/header';


function App() {

  const navigate = useNavigate()

  return (
    <>
      <LtHeader />
      <Container maxWidth="sm">
        <AuthProvider>

          <div className="card">
            <div className="card-content">
              <p className="title">Portal Literatour</p>
              <div className='content'>
                <i>Versão beta 0.1 </i> <br></br>
                Em caso de dificuldades no uso da plataforma, por favor entre em contato.
              </div>
              <div className="buttons">
                <button className="button is-primary is-fullwidth " onClick={() => navigate('/usuario')}>Alterar preferências</button>
                <button className="button is-link is-fullwidth" onClick={() => navigate('/assinatura')}>Avaliar minha última caixinha</button>
              </div>

              <div className='box' onClick={() => navigate('/trofeus')}>
                <div className='columns is-centered  box-centered'>
                  <div className='column is-narrow has-text-centered'>
                    <span className="icon-text icon-above">
                      <span className="icon has-text-primary">
                        <i className="fas fa-lg fa-trophy is-primary"></i>
                      </span>
                      <span>Trófeus</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className='box' onClick={() =>  window.open('https://www.literatour.com.br/revista-eletronica-do-mes/', '_blank')}>
                <div className='columns is-centered box-centered'>
                  <div className='column is-narrow has-text-centered'>
                    <span className="icon-text icon-above">
                      <span className="icon has-text-primary">
                        <i className="fas fa-lg fa-newspaper  is-primary"></i>
                      </span>
                      <span>Revista</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className='box' onClick={() =>  window.open('https://clubecerto.com.br/acesso?utm_cc=acessodireto&ent=literatour/', '_blank')}>
                <div className='columns is-centered box-centered'>
                  <div className='column is-narrow has-text-centered'>
                    <span className="icon-text icon-above">
                      <span className="icon has-text-primary">
                        <i className="fas fa-lg fa-newspaper  is-primary"></i>
                      </span>
                      <span>Benefícios</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <footer className="card-footer">
              <p className="card-footer-item">
                <span>
                  Comunidade oficial no  <a href="https://chat.whatsapp.com/H9rOur1lMy32IKH3MRGWMP">WhatsApp</a>
                </span>
              </p>
              <p className="card-footer-item">
                <span> Página no <a href="https://www.instagram.com/literatourclube/">Instagram</a> </span>
              </p>

              <div>
              </div>
            </footer>
          </div>         </AuthProvider>
      </Container>
    </>

  );
}

export default App;
