import { BaseApiResource } from "./base-api-resource";

import axios from "axios";
import { IEmailResource } from "./interfaces/email-resource.interface";

//Tpdp fazer factories para informar endpoints
export default class EmailResource  extends BaseApiResource implements IEmailResource {
    
    public constructor() {
        super("email/")
    }
    public send(recipientAddress : string, content : any) : Promise<boolean>
    {
        return axios.post(`${this.BACKEND_API_URL}${this.endpoint}/enviar/`,{recipientAddress, content}
        ).then((updatedModel : any) => {
            return updatedModel.data
   })

}

}