import { Container } from '@mui/material';
import LtHeader from '../../components/header';
import AuthProvider from '../../../domain/contexts/auth-context';



function AnniversaryRewardPage() {

  return (
    <>
      <LtHeader />
      <Container maxWidth="sm">
        <AuthProvider>

          <div className="card">
            <div className="card-content">
              <p className="title">Parabéns!</p>
              <div className='content'>
                <i>Você completou UM ANO de Literatour 🥹 </i> <br/>
                Como forma de agradecimento, queremos lhe enviar um presentinho bem especial que temos certeza que você irá amar
                <div>
                 </div>
                    
              </div>
              <div className="buttons">
                <button className="button is-primary is-fullwidth " onClick={() => window.open("https://api.whatsapp.com/send?phone=5519991251834&text=PRESENTE_1_ANO")}>Resgatar presente surpresa</button>
              </div>
            </div>
            <footer className="card-footer">
            
            </footer>
          </div>         </AuthProvider>
      </Container>
    </>

  );
}

export default AnniversaryRewardPage;
