import { useNavigate } from "react-router-dom";
import { useAuth } from "../domain/contexts/auth-context";
import { ReactNode, useEffect } from "react";

export type PrivateRouteProps =
  {
    children: ReactNode
  }
const PrivateRoute = (props: PrivateRouteProps) => {
  const { token, USER_IS_LOADING, USER_NOT_DEFINED  } = useAuth();
  const navigate = useNavigate()


  useEffect(() => {
    if(!token || token === USER_IS_LOADING) return
    if (token === USER_NOT_DEFINED) 
      {
        navigate('/login')
      }
  }, [token])


  return <>{props.children}</>;
};

export default PrivateRoute;