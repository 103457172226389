import { FeedbackTypeVO } from '../models/dtos/feedbackTypeVo';
import { FeedBackTypeEnum } from '../models/enums/feedbackTypeEnum';
import { IFeedbackTypeService } from './interfaces/feedback-type-service-interface';

export default class FeedbackTypeService implements IFeedbackTypeService {

  public findAll(): FeedbackTypeVO[] {


    let feedbackTypes = [{ label: "Livro Faltando", value: FeedBackTypeEnum.BAD_CONDITION_BOOK },
    { label: "Item Faltando", value: FeedBackTypeEnum.MISSING_ITEM },
    { label: "Livro em más condições", value: FeedBackTypeEnum.BAD_CONDITION_BOOK },
    {
      label: "Livro Repetido", value: FeedBackTypeEnum.REPEATED_BOOK,
    },
    {
      label: "Não gostei do livro", value: FeedBackTypeEnum.UNAPPRECIATED_BOOK
    },
    {
      label: 'Outro', value: FeedBackTypeEnum.OTHER
    }
    ]


    return feedbackTypes
  }


}
