export enum FeedBackTypeEnum {
    NO_PROBLEM = 10,
    MISSING_BOOK = 1,
    MISSING_ITEM = 2,
    UNAPPRECIATED_BOOK = 3,
    REPEATED_BOOK = 4,
    BAD_CONDITION_BOOK = 5,
    OTHER = 6
    


}