import { User } from '../models/user'
import { IBookSuggestionService } from './interfaces/book-suggestion-service-interface'
import { IBookSuggestionResource } from '../resources/interfaces/book-suggestion-resource-interface'
import { CategoryEnum } from '../models/enums/categoryEnum'
import { Book } from '../models/book'

export default class BookSuggestionService implements IBookSuggestionService {
  constructor (
    private readonly resource: IBookSuggestionResource,

  ) {

    this.resource = resource
  }


  //TODO: Refatorar para que seja possivel também chamar esse método sem filtrar por usuário
  public getReadingSuggestion (category: CategoryEnum, user : User): Promise<Book[]> {

    return this.resource.getReadingSuggestion(category, user)
    
  }

 
}
