import { AccessToken } from "../models/accessToken";
import { LoginVO } from "../models/dtos/loginVo";
import { User } from "../models/user";
import { BaseApiResource } from "./base-api-resource";
import { IAuthenticatorResource } from "./interfaces/authenticator-resource-interface";
import axios from "axios";

//Tpdp fazer factories para informar endpoints
export default class AuthenticatorResource
  extends BaseApiResource
  implements IAuthenticatorResource
{
  public constructor() {
    super("acesso/");
  }

  public store(user: User): Promise<User> {
    return axios
      .post(`${this.BACKEND_API_URL}${this.endpoint}`)
      .then((updatedModel : any) => {
        return updatedModel.data;
      });
  }

  public login(user: LoginVO): Promise<AccessToken> {
    return this.post(user ,'login');

  }

  public validateRecoveryCode(userMail: string, code: string): Promise<boolean> {

    return this.post({userMail, code}, 'ativar-conta')
   
  }
  public recoverPassword(userMail: string): Promise<boolean> {
    return this.post({ userMail },'recuperar-senha');
  }

  public updatePasswordRecoveredAccount(userMail: string, code: string, newPassword: string): Promise<boolean> {

    return this.post({userMail, code, newPassword}, 'atualiza-senha')
   
  }
  
}
