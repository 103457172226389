import { ITrophyService } from './interfaces/trophy-service-interface'
import { User } from '../models/user'
import { ITrophyResource } from '../resources/interfaces/trophy-resource-interface'

export default class TrophyService implements ITrophyService {
  constructor (
    private readonly resource: ITrophyResource,

  ) {

    this.resource = resource
  }

  public getTrophiesByUser (user: User, onlyOwned? : boolean): Promise<any[]> {

    return this.resource.getTrophiesByUser(user, onlyOwned)
    
  }

  public getRanking (untilTo : number): Promise<any[]> {

    return this.resource.getRanking(untilTo)
    
  }

 
}
