import { useEffect, useState } from "react"
import { ISubscriptionService } from "../../../domain/services/interfaces/subscription-service-interface"
import { SubscriptionEnum } from "../../../domain/models/enums/subscriptionEnum"
import { User } from "../../../domain/models/user"
import LtHeader from "../../components/header"
import LtStarRating from "../../components/star-rating/starRating"
import { IFeedbackTypeService } from "../../../domain/services/interfaces/feedback-type-service-interface"
import { FeedBackTypeEnum } from "../../../domain/models/enums/feedbackTypeEnum"
import { FeedbackTypeVO } from "../../../domain/models/dtos/feedbackTypeVo"
import { UserDTO } from "../../../domain/models/dtos/userDto"
import { CategoryEnum } from "../../../domain/models/enums/categoryEnum"
import { IKitService } from "../../../domain/services/interfaces/kit-service-interface"
import { toast } from "react-toastify";
import { UserWithSubscriptionDTO } from "../../../domain/models/dtos/userWithSubscriptionDTO"
import { LocalStorageKeys } from "../../../domain/resources/local-storage-keys"



export type SubscriptionPageProps = {
  service: ISubscriptionService
  feedbackTypeService: IFeedbackTypeService
  kitService: IKitService

}

const SubscriptionPage: React.FC<SubscriptionPageProps> = (props: SubscriptionPageProps) => {

  const userId = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY)


  const { service, feedbackTypeService, kitService } = props

  const defaultUser: UserWithSubscriptionDTO = {
    id: userId as unknown as number,
    email: "",
    categoriaPrimaria: CategoryEnum.CLASSICOS,
    categoriaSecundaria: CategoryEnum.CLASSICOS,
    idSkoob: "",
    aceita18: 0,
    tipoAssinatura: SubscriptionEnum.DETACHED
  }

  const [user, setUser] = useState<UserDTO>(defaultUser)


  const [subscriptionDetails, setSubscriptionDetails] = useState<any>()

  const [isDirty, setIsDirty] = useState<boolean>(false)


  const [kitFeedback, setKitFeedback] = useState<FeedBackTypeEnum>(FeedBackTypeEnum.NO_PROBLEM)

  const [kitRating, setKitRating] = useState<number>(5)
  const feedbackTypes = feedbackTypeService.findAll();


  const initializeView = () => {
    const user: User = {
      id: userId as unknown as number,
      email: "",
    }
    service.getDetails(user).then((setSubscriptionDetails))
  }


  const onClick = (value: number) => {
    setKitRating(value)
    setIsDirty(true)
  }

  const sendFeedback = () => {

    kitService.sendFeedback({ userId: user.id, feedback: kitRating, reason: kitFeedback })
    .then((ok) => toast.success('Feedback enviado com sucesso!')
  
  ).catch(error => toast.error('Erro ao efetuar feedback!') )

  }


  useEffect(() => {
    initializeView()
  }, [])

  useEffect(() => {
    setKitRating(subscriptionDetails?.ultimoFeedback)
  }, [subscriptionDetails])


  return (
    <>
      <LtHeader />
      <div className="lt-xp-view-subscription ml-5">

        <div className="columns">

          <div className="column">
            <div className="field">
              <label className="label">O que você achou da sua última caixinha?</label>
              <div className="control">
                <LtStarRating key={"lt-star" + Math.random()} onClick={onClick} value={kitRating} />

              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">Status da Assinatura</label>
              <p>{subscriptionDetails?.ativo === '1' ? 'ATIVO' : 'INATIVO'}</p>
              {subscriptionDetails?.ativo !== '1' ? <p className="help has-text-danger">  Entre em contato conosco para regularizar sua assinatura</p> : <></>}
            </div>
          </div>


        </div>
        {isDirty &&
          <div className="columns">
            <div className="column">


              {kitRating < 3 &&
                <div className="field">
                  <label className="label">Poxa, que pena ;/ Por que você não gostou?</label>
                  <div className="control control is-expanded">
                    <div className="select select is-fullwidth">
                      <select name="userFeedback" onChange={(event) => setKitFeedback(event.target.value as unknown as FeedBackTypeEnum)} value={kitFeedback} >
                        <option>Selecione</option>
                        {feedbackTypes.map((feedback: FeedbackTypeVO, key: number) =>
                          <option key={key} value={feedback.value}>{feedback.label}</option>
                        )}

                      </select>
                    </div>
                  </div>

                </div>}


              <div className="field">
                <div className="control">
                  <button onClick={sendFeedback} className="button is-link is-light">Enviar Feedback</button>
                </div>
              </div>
            </div>
          </div>}
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Próxima Caixinha</label>
              <p>{subscriptionDetails?.proximoEnvio?.dateSend}</p>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">Rastreio da última caixinha:</label>
              <p><em>Em breve...</em></p>
            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default SubscriptionPage
