export enum SubscriptionEnum {
BASIC = 1,
BASIC_TWO_BOOKS = 9,
EXTRA = 8,
EXTRA_TWO_BOOKS = 10,

SUBSCRIPTION_ENDED =5,


//Chaves legadas
PREMIUM_STANDARD = 2,
PREMIUM_GREAT = 3,
STANDARD_2020 = 7,
DETACHED = 4,
PARTNERSHIP = 6,


}