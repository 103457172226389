import { BaseApiResource } from "./base-api-resource";
import axios from "axios";
import { ITrophyResource } from "./interfaces/trophy-resource-interface";
import { User } from "../models/user";

export default class TrophyResource extends BaseApiResource implements ITrophyResource {

    public constructor() {
        super("trofeus/usuarios/")
    }

    public getTrophiesByUser(user: User, onlyOwned?: boolean): Promise<any[]> {

        return this.get(user.id as number)
        

    }


    public getRanking(untilTo: number): Promise<any[]> {

        return this.getByUrl(`${this.BACKEND_API_URL}${this.endpoint}/ranking}`
        )
    }

}