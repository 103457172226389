import { Category } from '../models/category';
import { CategoryConfig, ICategoryService } from './interfaces/category-service-interface'

export default class CategoryService implements ICategoryService {
  
  public findAll (config: CategoryConfig): Category[] {
    let dark = new Category("2", "Dark");
    let light = new Category("3", "Light");
    let people = new Category("4", "Pessoas");
    let romance = new Category("1", "Romance");
    let nerd = new Category("6", "Nerd");
    let classicos = new Category("5", "Clássicos");

    

    let categories =  [ dark, light, people, romance,nerd,classicos]

    if(config?.include18)
    {
      let romance18 = new Category("8", "Romance +18");
      categories.push(romance18);
    }

    if(config?.includeSurpresa)
    {
      let surpresa = new Category("9", "Surpresa");
      categories.push(surpresa);

    }
    return categories
  }

 
}
