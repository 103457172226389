import { useEffect, useState } from "react";
import { LocalStorageKeys } from "../../../domain/resources/local-storage-keys";
import { ITrophyService } from "../../../domain/services/interfaces/trophy-service-interface";
import LtHeader from "../../components/header";
import { UserDTO } from "../../../domain/models/dtos/userDto";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export type TrophiesPageProps = {
  service: ITrophyService;
};

const TrophiesPage: React.FC<TrophiesPageProps> = (props: TrophiesPageProps) => {
  const { service } = props;
  const navigate = useNavigate()


  const userId = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY);
  const [details, setDetails] = useState<any>({ dataCadastro: '', qtdKits: 0, qtdLivros: 0 });
  const [collapsedCards, setCollapsedCards] = useState<{ [key: string]: boolean }>({
    sixMonths : true,
    oneYear : true, 
    twoYears: true, 
    fiveKits: true,
    tenKits: true,
    twenty: true,
    twentyBooks: true,
    fiftyBooks: true,
    oneHundred: true
  });

  const initializeView = () => {
    const userDto: UserDTO = {
      id: userId as unknown as number,
      email: "carregando..."
    };
    service.getTrophiesByUser(userDto).then(setDetails);
  };


  useEffect(() => {
    initializeView();
  }, []);



  const isSubscriderSince = (years: number) => {
    const lastUpdate = moment(details?.dataCadastro);
    console.log(' E ASSINANTE DESDE', lastUpdate)
    //console.log('SIM OU NAO?', moment().diff(lastUpdate, 'years') >= years)


    return moment().diff(lastUpdate, 'years') >= years;
  };

  const getProgressValue = (current: number, total: number) => {
    return (current / total) * 100;
  };

  const getDaysDifference = (date: string) => {
    const lastUpdate = moment(date, "YYYY-MM-DD HH:mm:ss");
    return moment().diff(lastUpdate, 'days');
  };

  const toggleCard = (card: string) => {
    setCollapsedCards(prevState => ({
      ...prevState,
      [card]: !prevState[card]
    }));
  };

  return (
    <>
      <LtHeader />
      <div className="container">
        <div className="columns is-multiline">
          {/* Conquistas de tempo de permanência */}
          <div className="column is-full">
            <h2 className="title mt-2 has-text-centered has-text-weight-light	">Tempo de Casa</h2>
          </div>

          <div className="column is-half">
            <div className={`card has-text-centered ${!isSubscriderSince(0.5) ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">6 meses como assinante</p>
                <button className="card-header-icon" onClick={() => toggleCard('sixMonths')} aria-label="ver mais">
                  <span className="icon">
                    <i className={`fas ${collapsedCards['sixMonths'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.sixMonths && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-trophy fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={isSubscriderSince(0.5) ? 100 : getProgressValue(getDaysDifference(details?.dataCadastro), 182)} max="100">
                      100%
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                     isSubscriderSince(0.5) ?  
                        <a onClick={() => navigate('/7cx')}  className="card-footer-item">Resgatar acesso ao  7CX</a> 
                      
                        : <></>
                        

                    }
                  </footer>
                </>
              )}
            </div>

          </div>
          <div className="column is-half">
            <div className={`card has-text-centered ${!isSubscriderSince(1) ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title ">Um ano como assinante</p>
                <button className="card-header-icon" onClick={() => toggleCard('oneYear')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['oneYear'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.oneYear && (
                <>
                  <div className="card-content">
                    <span className="icon is-large" >
                      <i className="fas fa-trophy fa-3x"></i>
                    </span>
                    <progress className="progress  is-info" value={isSubscriderSince(1) ? 100 : getProgressValue(getDaysDifference(details?.dataCadastro), 365)} max="100">
                      {isSubscriderSince(1) ? '100%' : `${getProgressValue(getDaysDifference(details?.dataCadastro), 365)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">

                    {
                      isSubscriderSince(1) ?
                        <a onClick={() => navigate('/premio-1-ano')} className="card-footer-item">Resgatar surpresa 1 ano</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>

          </div>

          <div className="column is-half">
            <div className={`card has-text-centered ${!isSubscriderSince(2) ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">2 anos como assinante</p>
                <button className="card-header-icon" onClick={() => toggleCard('twoYears')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['twoYears'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.twoYears && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-trophy fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={isSubscriderSince(2) ? 100 : getProgressValue(getDaysDifference(details?.dataCadastro), 730)} max="100">
                      {isSubscriderSince(2) ? '100%' : `${getProgressValue(getDaysDifference(details?.dataCadastro), 730)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      isSubscriderSince(2) ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_2_ANOS_ASSINANTE" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>
          </div>

          {/* Conquistas de quantidade de kits recebidos */}
          <div className="column is-full">
            <h2 className="title mt-2 has-text-centered	 has-text-weight-light">Kits Recebidos</h2>
          </div>
          <div className="column is-half">
            <div className={`card has-text-centered ${details.qtdKits < 5 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">5 Kits Recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('fiveKits')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['fiveKits'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.fiveKits && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-box fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={details?.qtdKits >= 5 ? 100 : getProgressValue(details?.qtdKits, 5)} max="100">
                      {details?.qtdKits >= 5 ? '100%' : `${getProgressValue(details?.qtdKits, 5)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdKits >= 5 ?
                        <a href="https://www.literatour.com.br/satisfacao-garantida/" className="card-footer-item">Resgatar acesso antecipado ao Satisfação Garantida</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>

          </div>
          <div className="column is-half">
            <div className={`card has-text-centered ${details?.qtdKits <= 10 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">10 Kits Recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('tenKits')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['tenKits'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.tenKits && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-box fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={details?.qtdKits >= 10 ? 100 : getProgressValue(details?.qtdKits, 10)} max="100">
                      {details?.qtdKits >= 10 ? '100%' : `${getProgressValue(details?.qtdKits, 10)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdKits >= 10 ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_10_KITS" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>

          </div>
          <div className="column is-half">
            <div className={`card has-text-centered ${details?.qtdKits <= 20 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">20 Kits Recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('twentyKits')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['twentyKits'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.twentyKits && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-box fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={details?.qtdKits >= 20 ? 100 : getProgressValue(details?.qtdKits, 20)} max="100">
                      {details?.qtdKits >= 20 ? '100%' : `${getProgressValue(details?.qtdKits, 20)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdKits >= 20 ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_20_KITS" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>

          </div>

          {/* Conquistas de quantidade de livros recebidos */}
          <div className="column is-full">
            <h2 className="title mt-2 has-text-centered	 has-text-weight-light">Livros Recebidos</h2>
          </div>
          <div className="column is-half">

            <div className={`card has-text-centered ${details?.qtdLivros <= 20 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">20 livros recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('twentyBooks')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['twentyBooks'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.twentyBooks && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-book fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={details?.qtdLivros >= 20 ? 100 : getProgressValue(details?.qtdLivros, 20)} max="100">
                      {details?.qtdLivros >= 20 ? '100%' : `${getProgressValue(details?.qtdLivros, 20)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdLivros >= 20 ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_20_LIVROS" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>
          </div>
          <div className="column is-half">

            <div className={`card has-text-centered ${details.qtdLivros <= 50 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">50 livros recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('fiftyBooks')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['fiftyBooks'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.fiftyBooks && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-book fa-3x"></i>
                    </span>
                    <progress className="progress is-info" value={details?.qtdLivros >= 50 ? 100 : getProgressValue(details?.qtdLivros, 50)} max="100">
                      {details?.qtdLivros > 50 ? '100%' : `${getProgressValue(details?.qtdLivros, 50)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdLivros >= 50 ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_50_LIVROS" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>
          </div>

          <div className="column is-half">

            <div className={`card has-text-centered ${details?.qtdLivros <= 100 ? 'has-background-grey-lighter' : 'has-background-primary'}`}>
              <header className="card-header">
                <p className="card-header-title">100 livros recebidos</p>
                <button className="card-header-icon" onClick={() => toggleCard('hundredBooks')} aria-label="ver mais">
                  <span className="icon">
                  <i className={`fas ${collapsedCards['hundredBooks'] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
                  </span>
                </button>
              </header>

              {!collapsedCards.hundredBooks && (
                <>
                  <div className="card-content">
                    <span className="icon is-large">
                      <i className="fas fa-book fa-3x"></i>
                  </span>
                    <progress className="progress is-info" value={details?.qtdLivros >= 100 ? 100 : getProgressValue(details?.qtdLivros, 100)} max="100">
                      {details?.qtdLivros >= 100 ? '100%' : `${getProgressValue(details?.qtdLivros, 100)}%`}
                    </progress>
                  </div>
                  <footer className="card-footer">
                    {
                      details?.qtdLivros >= 100 ?
                      <a href="https://api.whatsapp.com/send?phone=5519991251834&text=RESGATE_100_LIVROS" className="card-footer-item">Resgatar</a> : <></>

                    }
                  </footer>
                </>
              )}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default TrophiesPage;
