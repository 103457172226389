import { Book } from "../models/book";
import { CategoryEnum } from "../models/enums/categoryEnum";
import { User } from "../models/user";
import { BaseApiResource } from "./base-api-resource";
import { IBookSuggestionResource } from "./interfaces/book-suggestion-resource-interface";

//Tpdp fazer factories para informar endpoints
export default class BookSuggestionResource  extends BaseApiResource implements IBookSuggestionResource {
    
    public constructor() {
        super("sugestoes-leitura/")
    }
    public getReadingSuggestion(category: CategoryEnum, user : User): Promise<Book[]>
    {
        return this.getByUrl(`${this.BACKEND_API_URL}${this.endpoint}usuario/${user.id}/categoria/${category}`
        )

}

}