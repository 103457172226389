import { useState } from "react"
import { useAuth } from "../../../domain/contexts/auth-context"
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


export type RecoverPasswordPageProps = {

}

const RecoverPasswordPage: React.FC<RecoverPasswordPageProps> = (props: RecoverPasswordPageProps) => {

  const navigate = useNavigate()


  const [email, setEmail] = useState<string>("")
  const [recoveryCode, setRecoveryCode] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")


  const [hasError, setHasError] = useState<boolean>(false)
  const [activationIsOk, setActivationIsOk] = useState<boolean>(false)
  const [showCodeField, setShowCodeField] = useState<boolean>(false)

  const auth = useAuth()

  const handleRecoveryPassword = () => {
    if (!email) {
      toast.error('Email obrigatório')
      setHasError(true)
      setShowCodeField(false)
      return

    }
    setHasError(false)

    auth.recoverPassword(email)
      .then((message) => {
        console.log('MESSAGE', message)
        if (message) {
          toast.success('Em alguns instantes, você receberá um email com seu código de recuperação de senha')
          setShowCodeField(true)
        } else {
          toast.info('Usuário não localizado na base de dados. Entre em contato.')

        }

      })
      .catch(error => toast.error('Falha no servidor. Tente novamente mais tarde'))


  }

  const handleValidateCode = () => {
    if (!recoveryCode) {
      toast.error('Código obrigatório')
      setHasError(true)
      setShowCodeField(false)
      return

    }


    auth.validateRecoveryCode(email, recoveryCode)
      .finally(() => {
        toast.success('Conta ativada com sucesso!')
        setHasError(false)
        setActivationIsOk(true)


      })

  }

  const handleNewPassword = () => {
    if (!newPassword) {
      toast.error('Nova senha obrigatório')
      setHasError(true)
      return


    }

    auth.updatePassword(email, recoveryCode, newPassword)
      .finally(() => {
        toast.success('Senha atualizada com sucesso!')
        setHasError(false)
        setActivationIsOk(true)
        navigate('/login')


      })



  }





  return (
<>
    <div className="lt-xp-form-password-recover">

      <div className="columns m-2">
        <div className="column">
          <div className="content">
          <p>Não sabe a senha? Não se preocupe.
          </p>
          <p> Basta nos informar seu e-mail de cadastro que geraremos uma nova e enviaremos em seu e-mail. </p>
          </div>
          
        </div>
      </div>


      <div className="columns m-2">
        <div className="column is-half">
          <div className="field">
            <label className="label">E-mail de cadastro(o mesmo da assinatura)</label>
            <div className="control">
              <input type="email" disabled={showCodeField} className={`input ${hasError ? 'is-danger' : ''}`} onChange={(event: any) => setEmail(event.target.value)} value={email} placeholder="Seu email" />
            </div>
          </div>

        </div>
      </div>

      {showCodeField &&
        <div className="columns m-2" >

          <div className="column ">
            <div className="field">
              <label className="label">Código de Acesso</label>
              <div className="control">
                <input type="number" minLength={4} className={`input ${hasError ? 'is-danger' : ''}`} onChange={(event: any) => setRecoveryCode(event.target.value)} value={recoveryCode} placeholder="12345678" />
              </div>
            </div>

          </div>
        </div>
      }


      {activationIsOk &&
        <div className="columns m-2">

          <div className="column">
            <div className="field">
              <label className="label">Nova Senha</label>
              <div className="control">
                <input type="password" minLength={4} max={12} className={`input ${hasError ? 'is-danger' : ''}`} onChange={(event: any) => setNewPassword(event.target.value)} value={newPassword} placeholder="12345678" />
              </div>
            </div>

            <div className="column">
              <button className="button is-link" disabled={!email} onClick={handleNewPassword}>Atualizar Senha</button>
            </div>


          </div>
        </div>
      }


      <div className="field is-grouped m-2">
      <div className="control">
      {
          !showCodeField && !activationIsOk ? <button className="button is-link" disabled={!email} onClick={handleRecoveryPassword}>Solicitar código de Acesso</button>
            : <></>
        }

{
          showCodeField  && !activationIsOk ?  <button className="button is-link" onClick={handleValidateCode}>Ativar Conta</button>

          : <></>
        }

        </div>

    
        <div className="control">
          <button className="button is-link is-light" onClick={() => setEmail("")}>Limpar</button>
        </div>
      </div>

    </div>
    </>

  )
}


export default RecoverPasswordPage
