import  { useEffect, useState } from "react";
import { useContext, createContext, ReactNode } from "react";
import useLiteratourAPI from "../hooks/use-literatour-api";
import { makeAuthenticatorService } from "../factories/services/authenticator-service-factory";
import { LoginVO } from "../models/dtos/loginVo";
import { LocalStorageKeys } from "../resources/local-storage-keys";




export type LiteratourContextProps = 
{   USER_IS_LOADING? : string,  
    USER_NOT_DEFINED?: string,
    loggedUserName? : any
    token? : string,
    login: (data: LoginVO) => Promise<boolean>
    logOut: () => void
    recoverPassword: (email: string) => Promise<boolean>
    validateRecoveryCode: (email: string, recoveryCode: string) => Promise<boolean>
    updatePassword: (email: string, code: string, newPassword: string) => Promise<boolean>




}
export type AuthProviderProps = 
{
   children: ReactNode 
}


const USER_IS_LOADING = 'token_ainda_nao_carregado'
const USER_NOT_DEFINED = 'token_ausente'



const AuthContext = createContext<LiteratourContextProps>(
    {
      login : () => Promise.resolve(false),
      logOut : () => console.warn('Logout não implementado ainda'),
      recoverPassword: async () => false,
      validateRecoveryCode: async () => false,
      updatePassword: async () => false


    });
    
   

function AuthProvider (props : AuthProviderProps)  {

  const authenticatinService = makeAuthenticatorService()

    const [loggedUserName , setLoggedUserName] = useState<string>("")
    const [token, setToken] = useState<string>(USER_IS_LOADING)




    const [requestHeaders] = useLiteratourAPI()

    const checkUserOnStorage = () =>
    {
      const token = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_KEY) 
      const userName = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_NAME_KEY) 

      const expirationDate = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_EXPIRATION_DATE_KEY) 


      // @ts-ignore
      const currentDate = new  Date()
      // @ts-ignore
      const tokenExpirationDate = new Date(expirationDate);

      if(token )
      {

        if (currentDate > tokenExpirationDate) {
          setToken(USER_NOT_DEFINED)

          setLoggedUserName(userName ?? "")

      
        } else {
          console.log('O token ainda é válido.');
          console.log('usuario esta logado',token)
        setToken(token)
        }
        
      }else
      {
        console.log('SEM TOKEN VALIDO...')
        logOut()
      }
    }

    //TODO: Centralizar essa chamada em AuthResource
      const recoverPassword = async (userMail: string) : Promise<boolean>   => {
        console.log('recuperara login', requestHeaders);
        return authenticatinService.recoverPassword(userMail)
      }

      

      const validateRecoveryCode = async (userMail: string, code: string) : Promise<boolean>   => {
        console.log('recuperara login', requestHeaders);
        return authenticatinService.validateRecoveryCode(userMail, code)
      }


      const updatePassword = async (userMail: string, code: string, newPassword: string) : Promise<boolean>   => {
        console.log('recuperara login', requestHeaders);
        return authenticatinService.updatePasswordRecoveredAccount(userMail, code,  newPassword)
      }
      
  
      const login = async (data : LoginVO) : Promise<boolean> => {
        console.log('entrou login context', requestHeaders);
       
          const response = await  authenticatinService.login(data)
          if (response) {
            console.log('Setando novo token...', response.token)
            setLoggedUserName(loggedUserName);
            localStorage.setItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_KEY, response.token);
            localStorage.setItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_EXPIRATION_DATE_KEY, response.dataExpiracao);
            localStorage.setItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY, response.userId);
            setToken(response.token);



            return true;
          }

          return false
        
      };

      const logOut = ()  => {
        console.log('LOGOUT')
        setLoggedUserName("");
        localStorage.removeItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_KEY);
        localStorage.removeItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_TOKEN_EXPIRATION_DATE_KEY);
        localStorage.removeItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_NAME_KEY);
        localStorage.removeItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY);
        setToken(USER_NOT_DEFINED);


      };
    
      useEffect(() => {
         checkUserOnStorage()
      }, [])
     

  return <AuthContext.Provider value={{USER_NOT_DEFINED, USER_IS_LOADING, token, loggedUserName,  login, logOut, recoverPassword, validateRecoveryCode, updatePassword}}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};