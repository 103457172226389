import { useNavigate } from "react-router-dom"
import { useAuth } from "../../domain/contexts/auth-context"

export type HeaderProps = {

}

const LtHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
    const navigate = useNavigate()
    const auth = useAuth()




    const openLiteratourFaq = () => {
        var url = "https://www.loja.literatour.com.br/duvidas-frequentes/";
        window.open(url, "_blank");
    }

    const logout = () => {
        auth.logOut()
        navigate('/login')

    }

    return (

        <section className="lt-xp-header hero is-primary is-medium is-5 p-1">
            
            <div className="hero-head">
                <nav className="navbar lt-navbar">
                    
                    <div className='lt-header-profile-link' onClick={logout}>
                        SAIR
                        <span className="icon-text">
                            <span className="icon">
                                <a>
                                    <i className="fa-solid fa-right-from-bracket"></i>

                                </a>
                            </span>
                        </span>
                    </div>



                </nav>
            </div>


            <div id='lt-header-menu-items' className="hero-foot">
                <nav className="tabs is-centered">
                    <div className="container">
                        <ul >
                                   
                            <li className="mr-1"><button className="button is-info lt-info   is-outlined is-medium is-responsive is-flex-direction-column pt-3" onClick={() => navigate('/usuario')}>
                            <span className="icon is-small m-o">
                            <i className="fa-solid fa-user"></i>                                </span>
                                <span className="lt-menu-item-description">Perfil</span>
                            </button></li>
                          
                            <li className="mr-1"><button className="button is-info lt-info    is-outlined   is-outlined  is-medium is-responsive is-flex-direction-column pt-3" onClick={() => navigate('/assinatura')}>
                                <span className="icon is-small m-o">
                                    <i className="fa-solid fa-info"></i>                                </span>
                                <span className="lt-menu-item-description">Assinatura</span>
                            </button></li>
                            <li className="mr-1" ><button className="button  is-info lt-info    is-outlined is-medium is-responsive is-flex-direction-column pt-3" onClick={() => navigate('/')}>
                                <span className="icon is-small m-0">
                                    <img className="header-logo-min" width='24px' height='16px' src="../../../assets/logo_no_bg.png" alt="Logo" />
                                    </span>
                                <span className="lt-menu-item-description"></span>
                            </button></li>
                            <li className="mr-1"><button className="button is-info lt-info    is-outlined is-medium is-responsive  is-flex-direction-column pt-3" onClick={openLiteratourFaq}>
                                <span className="icon is-small m-o">
                                    <i className="fa-solid fa-question"></i>                                </span>
                                <span className="lt-menu-item-description">Ajuda</span>
                            </button></li>
                            <li className="mr-0 lt-logout-desktop-icon"><button className="button  is-info lt-info    is-outlined  is-medium is-responsive is-flex-direction-column pt-3" onClick={logout}>
                                <span className="icon is-small m-0 is-flex-direction-column">
                                <i className="fa-solid fa-right-from-bracket"></i>
                                <span className="lt-menu-item-description">Sair</span>
                                </span>
                            </button></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>


    )
}

export default LtHeader
