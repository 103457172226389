import { BaseApiResource } from "./base-api-resource";
import { IWoocommerceResource } from "./interfaces/woocommerce-resource-interface";
import axios from "axios";



 const SITE_URL: string = "https://literatour.com.br/";

export default class WoocommerceResource extends BaseApiResource implements IWoocommerceResource {
    public constructor() {
        super("woocommerce/")
           
    }
    public getLastTracking(subscriberMail: string) : Promise<any>
    {
        //_wcs_subscription_ids_cache metadata
                return axios.get(SITE_URL + "wp-json/wc/v3/orders", 
                    {
                      params : { search: subscriberMail},
                      auth   : {  username: 'ck_a6c8529cde896bd878ed42bb99fc757134ba16ab',
                                 password: 'cs_c5b1f053c045dbcbd895ee5ce23d93d62a7441a4'}
                })
    }

       

 
   
}