import axios from "axios";
import { IBaseApiResource } from "./interfaces/base-api-resource";
import {  toast } from 'react-toastify';



export class BaseApiResource implements IBaseApiResource {
  endpoint: string = "/";
  BACKEND_API_URL: string = "https://api-v2.literatour.com.br/";

  headers = {
    "Content-Type": "application/json",
    "Accept": "text/javascript",
    "Authorization": "Basic bGl0ZXJhdG91clVzZXI6bDJrY0FwcEBQYXNz",
  };


  constructor(endpoint: string) {
  this.endpoint = endpoint

  }

//TODO: Criar métodos ainda mais puros, com lista de parametros via objeto de configuracao ou outra ideia para prover os headers e o uso do axios.
  public post(resource: any, address?: string) { 
    return axios.post(`${this.BACKEND_API_URL}${this.endpoint}${address ?? ""}`, resource, {headers: this.headers})
    .then((updatedModel : any) =>  updatedModel.data)
    .catch(error => toast.error("Erro ao realizar requisição"))
  }
 public  get(id: number, resourceAddress? : string){
   return axios.get(`${this.BACKEND_API_URL}${this.endpoint}${resourceAddress ?? ""}${id}`, {headers: this.headers}).then((updatedModel : any) =>  updatedModel.data)
  }

  public  getByUrl( url : string){
    return axios.get(url, {headers: this.headers}).then((updatedModel) =>  updatedModel.data)
   }
 

  

 public  update(id: number, resource: any, address?: string){
   return axios.put(`${this.BACKEND_API_URL}${this.endpoint}${address?? ""}${id}`, resource, {headers: this.headers}).then((updatedModel) =>  updatedModel.data)}

  public remove (id: number, resourceAddress? : string){
     return axios.delete(`${this.BACKEND_API_URL}${this.endpoint}${resourceAddress ?? ""}${id}`, {headers: this.headers}).then((updatedModel) =>  !!updatedModel )}

}
