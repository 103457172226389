export enum CategoryEnum {
    ROMANCE = "1",
    DARK = "2",
    LIGHT = 3,
    PESSOAS = "4",
    CLASSICOS = "5",
    NERD = "6",
    ROMANCE_18 ="8",
    SURPRESA ="9"
    


}