import { CategoryEnum } from "../enums/categoryEnum"
import { QuinzenaEnum } from "../enums/quinzenaEnum"

export class UserDTO {
     id? : number
     email : string  = ""
     nome?: string  = ""
     obs?: string   = ""
     quinzenaEnvio?: QuinzenaEnum
     categoriaPrimaria?: CategoryEnum  
     categoriaSecundaria? : CategoryEnum 
     statusRenovacao? : string 
     aceita18? : number ;
     kitFeito? : boolean 
     idSkoob? : string  = "Não inforrmado"
     dataCadastro? : string  
  }