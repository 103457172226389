import { User } from '../models/user'
import { ISubscriptionService } from './interfaces/subscription-service-interface'
import { ISubscriptionResource } from '../resources/interfaces/subscription-resource-interface'
import { Subscription } from '../models/subscription'
import { UserWithSubscriptionDTO } from '../models/dtos/userWithSubscriptionDTO'

export default class SubscriptionService implements ISubscriptionService {
  constructor (
    private readonly resource: ISubscriptionResource,

  ) {

    this.resource = resource
  }

  public getNextShipment (user: User): Promise<any> {

    return this.resource.getNextShipment(user)
    
  }

  
  public getLastKits (user: User): Promise<any[]> {

    return this.resource.getLastKits(user)
  
  }

public  update (userId: number, subscription: UserWithSubscriptionDTO) : Promise<Subscription>
{
  return this.resource.updateSubscription(userId,  subscription)

}

  
 
}
