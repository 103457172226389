import { useEffect, useState } from "react"
import { Category } from "../../../domain/models/category"
import { SubscriptionEnum } from "../../../domain/models/enums/subscriptionEnum"
import { ICategoryService } from "../../../domain/services/interfaces/category-service-interface"
import { IUserService } from "../../../domain/services/interfaces/user-service-interface"
import { UserDTO } from "../../../domain/models/dtos/userDto"
import { CategoryEnum } from "../../../domain/models/enums/categoryEnum"
import LtHeader from "../../components/header"
import { toast } from "react-toastify"
import { ISubscriptionService } from "../../../domain/services/interfaces/subscription-service-interface"
import { Subscription } from "../../../domain/models/subscription"
import { UserWithSubscriptionDTO } from "../../../domain/models/dtos/userWithSubscriptionDTO"
import { LocalStorageKeys } from "../../../domain/resources/local-storage-keys"


export type UserPageProps = {
service: IUserService
subscriptionService: ISubscriptionService
categoryService: ICategoryService
}

const UserPage: React.FC<UserPageProps> = (props: UserPageProps) => {

  const userId = localStorage.getItem(LocalStorageKeys.LOCAL_STORAGE_AUTH_USER_ID_KEY) 

  const {service, categoryService, subscriptionService} = props

  const [user, setUser] = useState<UserWithSubscriptionDTO>(new UserWithSubscriptionDTO())

  const [isDirtyUser, setIsDirtyUser] = useState<boolean>(false)
  const [hasAlteracaoPlano, setHasAlteracaoPlano] = useState<boolean>(false)
  const [isDirtySubscription, setIsDirtySubscription] = useState<boolean>(false)





  const categories =  categoryService.findAll({ includeSurpresa: true });

  const initializeForm = () =>
  {
    const user : UserDTO = {
       id: userId as unknown as number ,
      email: "carregando...",
    
    }
    service.get(user).then(setUser)
    
  }

 
  const update = () =>
  {
 if(isDirtySubscription)
 {
    updateSubscription()
 }
 if(isDirtyUser)
 {
  updateUser()

 }
  }

  const updateUser = () =>
    {
         const userToUpdate = {...user}
         delete userToUpdate.idAssinatura
         delete userToUpdate.nomeCidade


      service.update(userToUpdate)
      .then((_user : UserDTO) => toast.success('Preferências atualizadas com sucesso!'))
      .catch((_error : any) => toast.error('Falha ao atualizar! Tente mais tarde'))
    }
  

    const updateSubscription = () =>
    {
      console.log('marcando alteracao plano...')
      const userToUpdate = {...user, alteracaoPlano  :true}
            subscriptionService.update(userToUpdate?.id as number, userToUpdate)
        .then((_subscription : Subscription) => toast.success('Assinatura atualizada com sucesso!'))
        .catch((_error : any) => toast.error('Falha ao atualizar! Tente mais tarde'))
      }
      
    
  const reset = () =>
  {
    initializeForm()
  }


  useEffect(() => {
    initializeForm()
 },[])


  return (
<>
<LtHeader/>

    <div className="lt-xp-form-user ml-5">

     <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Nome Completo</label>
            <div className="control">
              <input  readOnly   className="input" type="text" value={user.nome} placeholder="João da Silva" />
            </div>
          </div>
        </div>
  
        <div className="column">
          <div className="field">
            <label className="label">E-mail</label>
            <div className="control">
              <input  readOnly className="input" type="text" value={user.email} placeholder="assinante@gmail.com" />
            </div>
          </div>

        </div>
      </div>

  
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Categoria Primária</label>
            <div className="control control is-expanded">
              <div className="select select is-fullwidth">
              <select  name="categoriaPrimaria" onChange={(event:  React.ChangeEvent<HTMLSelectElement>) => {
                                setIsDirtyUser(true)
                setUser({...user, categoriaPrimaria : event.target.value as CategoryEnum}) }} value={user.categoriaPrimaria}>
                <option>Selecione</option>
                  {categories.map((category : Category, key : number) => 
                    <option key={"categoriaPrimaria" + key} value={category.id}>{category.label}</option>
                  )}
                 
                </select>
              </div>
            </div>          
            </div>
        </div>
        <div className="column">
          <div className="field">
          <label className="label">Categoria Secundária</label>
            <div className="control control is-expanded">
            <div className="select select is-fullwidth">
                <select  name="categoriaSecundaria" onChange={(event:  React.ChangeEvent<HTMLSelectElement>) => {
                  setIsDirtyUser(true)
                  setUser({...user, categoriaSecundaria : event.target.value as CategoryEnum }) }} value={user.categoriaSecundaria}>
                <option>Selecione</option>
                  {categories.map((category : Category, key : number) => 
                    <option key={"categoriaSecundaria" + key} value={category.id}>{category.label}</option>
                  )}
                 
                </select>
              </div>
            </div>            </div>

        </div>
      </div>

      <div className="columns">
        <div className="column">
          <div className="field">
          <label className="label">Aceita +18?</label>
            <div className="control control is-expanded">
              <div className="select select is-fullwidth">
                <select name="aceita18" value={user.aceita18}  onChange={(event:  React.ChangeEvent<HTMLSelectElement>) => {
                  setIsDirtyUser(true)
                  setUser({...user, aceita18 : (parseInt(event.target.value)) }) }}  >
                  <option>Selecione</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>
            </div>  
          </div>
        </div>

        <div className="column">
          <div className="field">
            <label className="label">ID Skoob(apenas números)</label>
            <div className="control">
              
              <input name="idSkoob" className="input" type="number" placeholder="ex: 9922122" value={user.idSkoob} onChange={(event:  React.ChangeEvent<HTMLInputElement>) => {
                setIsDirtyUser(true)
                setUser({...user, idSkoob : event.target.value}) }} />
            </div>
          </div>
        </div>

        <div className="column">
          <div className="field">
          <label className="label">Plano</label>
            <div className="control control is-expanded">
              <div className="select select is-fullwidth">
                <select  name="tipoAssinatura" value={user.tipoAssinatura}   onChange={(event:  React.ChangeEvent<HTMLSelectElement>) => 
                {setUser( {...user, tipoAssinatura : (parseInt(event.target.value)) })
                setHasAlteracaoPlano(true)
                setIsDirtySubscription(true)
                setIsDirtyUser(true)
                }} >
                  <option value={SubscriptionEnum.BASIC}>Básico</option>
                  <option value={SubscriptionEnum.BASIC_TWO_BOOKS}>Básico(com 2 livros)</option>
                  <option value={SubscriptionEnum.EXTRA}>Extra</option>
                  <option value={SubscriptionEnum.EXTRA_TWO_BOOKS}>Extra(com 2 livros)</option>
                </select>
              </div>


              {hasAlteracaoPlano ? <p className="help has-text-info">  Atenção: Levará até dois dias úteis para que sua alteração de plano seja efetivada</p> : <></>}

            </div>  
          </div>

        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button onClick={update} className="button is-link">Atualizar</button>
        </div>
        <div className="control">
          <button onClick={reset} className="button is-link is-light">Cancelar</button>
        </div>
      </div>

    </div>
    </>

  )
}

export default UserPage
