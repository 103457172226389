import { IEmailService } from './interfaces/email-service-interface'
import { IEmailResource } from '../resources/interfaces/email-resource.interface'

export default class EmailService implements IEmailService {
  constructor (
    private readonly resource: IEmailResource,

  ) {

    this.resource = resource
  }


  public send (recipientAddress : string, content : any): Promise<boolean> {

    return this.resource.send(recipientAddress, content)
    
  }

 
}
