import { BaseApiResource } from "./base-api-resource";
import { ISubscriptionResource } from "./interfaces/subscription-resource-interface";
import { User } from "../models/user";
import { Subscription } from "../models/subscription";
import { UserWithSubscriptionDTO } from "../models/dtos/userWithSubscriptionDTO";

export default class SubscriptionResource extends BaseApiResource implements ISubscriptionResource {

    public constructor() {
        super("assinaturas/")
    }


    public getDetails(user: User): Promise<any> {
        if(!user.id) return Promise.resolve(false)
            console.log('ID ASSINATURA',user.id)
        return this.get(user.id, 'detalhes/')
    
    }


    public getNextShipment(user: User): Promise<any> {
        if(!user.id) return Promise.resolve(false)
        return this.get(user.id, 'proximo-envio')

    }


    public getLastKits(user: User): Promise<any> {
        if(!user.id) return Promise.resolve(false)
        return this.get(user.id, 'ultimos-envios')

    }

    public updateSubscription(subscriptionId : number,subscription : UserWithSubscriptionDTO): Promise<Subscription> {
        return this.update(subscriptionId, subscription)

    }




}