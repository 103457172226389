import { SubscriptionEnum } from "../enums/subscriptionEnum"
import { UserDTO } from "./userDto"

export class UserWithSubscriptionDTO extends UserDTO {
    
     idAssinatura?: number
     tipoAssinatura?: SubscriptionEnum 
     alteracaoPlano? : boolean
     nomeCidade? : string
    
  }